import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css'

function Header() {
  return (
    <body class = "header">
      <nav>
        <ul class = "shadow-button-set">
            <li><button><Link to="/blog">My Blogs</Link></button></li>
            <li><button><Link to="/about">About me</Link></button></li>
            <li><button><Link to="/projects">My Projects</Link></button></li>
            <li><button><Link to="/contact">Contact</Link></button></li>
          {/* Add additional navigation links as needed */}
        </ul>
      </nav>
    </body>
  );
}

export default Header;
