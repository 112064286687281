import React from 'react';
import Header from "../components/Header"
import styles from '../module/contact.module.css'; // Assuming you have a CSS file named style.css in the same directory
import gitIco from '../resources/github.png'
import linkIco from '../resources/linkedin.png'
import mediumIco from '../resources/medium.png'


function CardsHover2() {
  return (
    <div>

      <Header />
      <div className={styles.boddy}>
        <div className={styles.cont}>

          <div className={styles.Card}>
            <div className={`${styles.face} ${styles.face1}`}>
              <div className="content">
                <div className="icon">

                  <a href='https://github.com/TolunayM'>
                    <img src={gitIco} alt="Icon" style={{ width: '100px', height: '100px' }} />
                  </a>

                </div>
              </div>
            </div>
            <div className={`${styles.face} ${styles.face2}`}>
              <div className="content">
                <h3>
                  <a href={"https://github.com/TolunayM"} target="no-target-blank">{"@TolunayM"}</a>
                </h3>
                <p>{"Github"}</p>
              </div>
            </div>
          </div>





          <div className={styles.Card}>
            <div className={`${styles.face} ${styles.face1}`}>
              <div className="content">
                <div className="icon">

                  <a href='https://www.linkedin.com/in/tolunaymutlu/'>
                    <img src={linkIco} alt="Icon" style={{ width: '100px', height: '100px' }} />
                  </a>

                </div>
              </div>
            </div>
            <div className={`${styles.face} ${styles.face2}`}>
              <div className="content">
                <h3>
                  <a href={"https://www.linkedin.com/in/tolunaymutlu/"} target="no-target-blank">{"@TolunayMutlu"}</a>
                </h3>
                <p>{"Linkedin"}</p>
              </div>
            </div>
          </div>



          <div className={styles.Card}>
            <div className={`${styles.face} ${styles.face1}`}>
              <div className="content">
                <div className="icon">

                  <a href='https://medium.com/@tlnymtlu'>
                    <img src={mediumIco} alt="Icon" style={{ width: '100px', height: '100px' }} />
                  </a>

                </div>
              </div>
            </div>
            <div className={`${styles.face} ${styles.face2}`}>
              <div className="content">
                <h3>
                  <a href={"https://medium.com/@tlnymtlu"} target="no-target-blank">{"@TnyMtlu"}</a>
                </h3>
                <p>{"Medium"}</p>
              </div>
            </div>
          </div>




        </div>
      </div>


    </div>

  );
};

export default CardsHover2;
