import React from 'react';
import Header from '../components/Header';
import style from '../module/projects.module.scss'
import githubIco from '../resources/github.png'
import springIco from '../resources/springboot.svg'
import golangIco from '../resources/golang.svg'
import javaIco from '../resources/java.png'
import jsIco from '../resources/js.png'


const Card = ({ imageUrl, title, description, techStack, githubLink }) => {
  return (
    <div className={style.boody}>
      <div className={style.card}>
        <img src={imageUrl} className={style.card__image} alt='icon' />
        <div className={style.card__content}>
          <span className={style.card__title}>{title}</span>
          <br /><br />
          <p className={style.card__description}>{description}</p>
          <p className={style.card__tech_stack}>
            <strong>Built with:</strong><br />
            {techStack.map((tech, index) => <span key={index}>{tech}<br /></span>)}
          </p>
          <a href={githubLink}>
            <img src={githubIco} className={style.card__secImage} alt='github icon' />
          </a>
          <figcaption className={style.card__git}>Check it out on github</figcaption>
        </div>
      </div>
    </div>
  );
};

function Projects() {
  const projectsData = [

    {
      imageUrl: springIco,
      title: 'Microservice CRM',
      description: 'CRM project based Microservice Architecture for Turkcell',
      techStack: ['spring boot', 'postgre', 'kafka','jwt'],
      githubLink: 'https://github.com/TolunayM/Turkcell-Micro-CRM'
    },
    {
      imageUrl: springIco,
      title: 'WhatsInit',
      description: 'Command-line tool for Spring Boot dependencies',
      techStack: ['java', 'spring shell', 'h2'],
      githubLink: 'https://github.com/TolunayM/whatsinit'
    },
    {
      imageUrl: golangIco,
      title: 'd3u',
      description: 'Command-line tool for Nvidia DLSS Management',
      techStack: ['golang', 'cobra-cli', 'bbolt'],
      githubLink: 'https://github.com/TolunayM/d3u'

    },
    {
      imageUrl: javaIco,
      title: 'PamuKariyer',
      description: 'Employee-employer matching portal based on skills and interests.',
      techStack: ['java', 'firebase', 'ews'],
      githubLink: 'https://github.com/TolunayM/PamuKariyer'
    },
    {
      imageUrl: golangIco,
      title: 'go-ydl',
      description: 'Youtube video or audio downloader',
      techStack: ['golang', 'fyne'],
      githubLink: 'https://github.com/TolunayM/go-ydl'
    },
    {
      imageUrl: jsIco,
      title: 'PreMedium',
      description: 'Small Chrome extension for bypass medium paywall.',
      techStack: ['javascript'],
      githubLink: 'https://github.com/TolunayM/premedium'
    }
  ];

  return (
    <div>
      <Header />
      {/* Render Card component for each object in cardsData */}
      {projectsData.map((projects, index) => (
        <Card key={index} {...projects} />
      ))}
    </div>
  );
};

export default Projects;