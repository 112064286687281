import React from 'react';
import Header from '../components/Header';
import styles from '../module/blog.module.scss'
import dockerIco from '../resources/docker.png'
import springIco from '../resources/springboot.svg'
import loadbalancer from '../resources/load-balancer.png'
import cloud from '../resources/cloud.png'
const Card = ({ imageURL, title, date, link, secImageURL }) => {

    return (
        <div class={styles.boody}>
        <div class={styles.card}>
            <img src={imageURL} class={styles.card__image} alt='icon' />
            <div class={styles.card__content}>
                <time class={styles.card__date}>{date}</time>
                <span class={styles.card__title}>{title}</span>
                <br></br>
                <br></br>
                <a class={styles.card__link} href={link}> {'-'}Read On Medium</a>
                <img src={secImageURL} class={styles.card__secImage} alt='icon' />
            </div>
        </div>
        </div>

    );

};

function Blog() {
    const blogsData = [
        
        {
            imageURL:loadbalancer,
            title:'What is Load Balancing?',
            date: '18 April 2024',
            link:'https://medium.com/@tlnymtlu/what-is-load-balancing-6cfee627108f',
            secImageURL:cloud,
        },
        {
            imageURL:loadbalancer,
            title:'Load Balancing Nedir ?',
            date: '18 April 2024',
            link:'https://medium.com/@tlnymtlu/load-balancing-nedir-5ad149bbe880',
            secImageURL:cloud,
        },
        {
            imageURL: dockerIco,
            title: 'Launching Our Spring Boot Application as a Docker Container',
            date: '5 December 2023',
            link: 'https://medium.com/@tlnymtlu/launching-our-spring-boot-application-as-a-docker-container-bf807cd972ef',
            secImageURL: springIco

        },
        {
            imageURL: dockerIco,
            title: 'Spring Boot Uygulamamızı Docker Container Olarak Ayağa Kaldırmak',
            date: '31 May 2023',
            link: 'https://medium.com/@tlnymtlu/spring-boot-uygulamam%C4%B1z%C4%B1-docker-container-olarak-aya%C4%9Fa-kald%C4%B1rmak-1976bf45aefd',
            secImageURL: springIco

        }
    ];


    return (
        <div>
            <Header />
                {blogsData.map((blogs, index) => (
                    <Card key={index} {...blogs} />
                ))}
        </div>
    
    );
};

export default Blog;