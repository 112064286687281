import React from 'react';
import '../styles/Landing.css'
import { Link } from 'react-router-dom';

function Landing() {
  return (



    <div class="wrapper">
      <div class="typing-demo">
        Hi, I am Tolunay!
      </div>
      <div class="typing-demo-second">
        I'm a Software Engineer who develop himself as a Backend Engineer.
      </div>

      <div class="landing-body">
        <nav>
          <ul class="shadow-button">
            <li><button><Link to="/blog">My Blogs</Link></button></li>
            <li><button><Link to="/about">About me</Link></button></li>
            <li><button><Link to="/projects">My Projects</Link></button></li>
            <li><button><Link to="/contact">Contact</Link></button></li>

            {/* Add additional navigation links as needed */}
          </ul>
        </nav>
      </div>
    </div>

  );
}

export default Landing;